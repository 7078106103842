<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptConvertPartnerOrder"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('PARTNERORDER.CONVERT.HEADER.TEXT')}}</p>
          <div class="default-input d-flex align-items-center">
            <vs-select v-model="numbergroup_fk" class="" :danger="numbergroup_fk_fail" :label="$t('PARTNERORDER.CONVERT.OFFER_NUMBERGROUP')">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in numbergroups" />
            </vs-select>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <vs-input ref="bez" :label="$t('PARTNERORDER.CONVERT.LABEL.BEZ')"  class="float-left mr-2 inputx" :placeholder="$t('PARTNERORDER.CONVERT.LABEL.PLACEHOLDER.BEZ')"  v-model="bez" :danger="bez_fail" val-icon-danger="clear"/>
          </div>
          <div class="default-input d-flex align-items-center mb-3 mt-2">
            <vs-checkbox :disabled="!isEuVisible" v-model="isEu"  class="inputx" >{{$t('PARTNERORDER.CONVERT.LABEL.EU')}}</vs-checkbox>
          </div>          
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import {numbergroup_Types} from '../../../helper/enumHelper';

export default {
  name: "ConvertPartnerOrderDlg",
  props:[],
  data: function (){
     return {
       dlgActive:false,
       numbergroups:[],
       popupTitle:this.$t('PARTNERORDER.CONVERT.TITLE'),
       numbergroup_fk:-1,
       bez:"",
       isEu:false,
       isEuVisible:false,
       bez_fail:false,
       bez_ok:false,
       defaultNrGrpFk:-1,
       partnerorder_fk:0,
       numbergroup_fk_fail:false,
       numbergroup_fk_ok:false
     }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ShowDlg(partnerorder_fk){
        this.ClearForm();
        this.partnerorder_fk = partnerorder_fk;

        if(this.defaultNrGrpFk != -1)
        {
          this.numbergroup_fk = this.defaultNrGrpFk;
        }
        
        this.dlgActive=true;
        this.IsEu();
      },
      closed(){
        this.ClearForm();
      },
      accepted(){

          var data = {
            partnerorder_fk:this.partnerorder_fk,
            numbergroup_fk:this.numbergroup_fk,
            bez:this.bez,
            isEu:this.isEu
          };

          var name = this.bez;
          this.$store.dispatch('partnerorder/convertToOffer', data)
          .then(response => {
            if(response.success == true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('PARTNERORDER.SUCCESS.CONVERT').replace("*1*",name)});  
              this.$emit("Success",response.id);
            }
            else
            {
              var text = this.$t('PARTNERORDER.WARNING.CONVERT').replace("*1*",name);
              text = text.replace("*2*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{

              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.CONVERT').replace("*1*",error)});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.numbergroup_fk = -1;
        this.bez = "";
        this.numbergroup_fk_fail = false;
        this.numbergroup_fk_ok = false;
        this.bez_fail = false;
        this.bez_ok = false;
        this.partnerorder_fk=0;
        this.isEu = false;
        this.isEuVisible = false;
      },
      IsEu(){

          this.$store.dispatch('partnerorder/isEu', this.partnerorder_fk)
          .then(response => {
            if(response.success == true)
            {
              if(response.text == "1")
              {
                this.isEu = true;
                this.isEuVisible = true;
              }
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.CONVERT').replace("*1*",response.text)});  
              this.dlgActive = false;
            }
          }, 
          error =>{

              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('PARTNERORDER.ERROR.CONVERT').replace("*1*",error)});  
              this.dlgActive = false;
          });
      },
  },
  computed:{
    formValid(){
      return this.bez_ok && this.numbergroup_fk_ok;
    },
    numbergroupsForSelect(){
      return this.GetListOfNumbergroupsForSelect(numbergroup_Types.ANGEBOT);
    },
  },
  watch:{
    numbergroupsForSelect(value)
    {
        this.numbergroups = value.numbergroups;
        this.defaultNrGrpFk = value.default;
    },
    bez(value)
    {
      this.bez_fail = value.length == 0;
      this.bez_ok = !this.bez_fail;
    },
    numbergroup_fk(value){
      this.numbergroup_fk_fail = value <= 0;
      this.numbergroup_fk_ok = !this.numbergroup_fk_fail;
    },
  }
};
</script>
<style>
div#promptAddpromptConvertPartnerOrderOffer > div.vs-dialog{
  max-width: 800px !important;
}
div#promptConvertPartnerOrder > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}
</style>